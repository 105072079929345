export const ROOT = "/";
export const REGISTER = "/cadastro";
export const CHANGE_PASSWORD_LINK = "/redefinir-senha";
export const NEW_PASSWORD = "/nova-senha";
export const SUCESS_NEW_PASSWORD = "/senha-salva";
export const ERROR_PAGE = "/erro";

export const USER_HOME = "/usuario/home";
export const USER_REGISTRATION_DATA = "/usuario/dados-cadastrais";
export const USER_MURAL = "/usuario/mural";
export const USER_CONTRACTION = "/usuario/contratacao";
export const USER_PAYMENTS = "/usuario/pagamentos";
export const USER_DIDNT_FIND = "/usuario/nao-encontramos";
