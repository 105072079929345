import React from "react";
import { LogoContainer, Logo, Container, Loader } from "./styles";

import greenLogo from "../../assets/images/Preloader/green_logo.png";

const Preloader = () => (
  <Container>
    <LogoContainer>
      <Logo src={greenLogo} alt="Logo Verde do FIEF" />
    </LogoContainer>

    <Loader />
  </Container>
);

export default Preloader;
