import axios from "axios";
import {
  GET_ALL_CONTRACTS,
  GET_CONTRACT,
  GET_BOLETO,
  GET_BOLETO_VENCIDO,
} from "./CONSTANTS";

const BASE_URL = process.env.REACT_APP_API;

const api = axios.create({ baseURL: BASE_URL });

export const getAllContracts = (token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return api.get(GET_ALL_CONTRACTS(), config);
};

export const getContract = (id, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return api.get(GET_CONTRACT(id), config);
};

export const getBoleto = (token) => `${BASE_URL}${GET_BOLETO(token)}`;

export const getBoletoVencido = (token, id) =>
  `${BASE_URL}${GET_BOLETO_VENCIDO(token, id)}`;
