import React from "react";

import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors.white};
`;

export const ErrorPage = () => (
  <Container>
    <h2>O Nosso Sistema retornará em breve</h2>
    <p>
      No momento, o nosso sistema está fora do ar para uma manutenção. Por
      favor, tente mais tarde
    </p>
  </Container>
);
