import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { checkAuthState } from "./services";

import Router from "./navigation/RouterConfig";

import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const callback = async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        dispatch({
          type: "LOGGED_IN_USER",
          payload: {
            token: idTokenResult.token,
          },
        });
      }
    };

    const unsubscribe = checkAuthState(callback);
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <ThemeProvider theme={light}>
      <GlobalStyles />
      <ToastContainer />
      <Router />
    </ThemeProvider>
  );
};

export default App;
