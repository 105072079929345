import styled from "styled-components";
import { Spinner } from "reactstrap";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10%;

  background: ${(props) => props.theme.colors.white};
`;

export const LogoContainer = styled.div`
  max-width: 240px;
`;

export const Logo = styled.img`
  width: 100%;
`;

export const Loader = styled(Spinner)`
  margin-top: 10px;
  width: 3rem;
  height: 3rem;
  color: ${(props) => props.theme.colors.success};
`;
