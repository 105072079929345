import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 100%;
  }

  html, body, #root {
    height: 100%;
  }

  *, button, input {
    border: 0;
    outline: 0;
  }

  button {
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }

  li {
  list-style-type: none;
}

  ::-webkit-scrollbar {
  width: 8px;
    @media (max-width: 768px) {
    width: 4px;
    }
  }

  ::-webkit-scrollbar-track {
  background: ${(props) => props.theme.colors.white};
  }

  ::-webkit-scrollbar-thumb {
  background: ${(props) => props.theme.colors.success};
  border-radius: 50px;
  }
`;
