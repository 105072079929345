import axios from "axios";
import { CREATE_USER } from "./CONSTANTS";
import { auth } from "./firebase";

const BASE_URL = process.env.REACT_APP_API;

const api = axios.create({ baseURL: BASE_URL });

export const createUser = (newUser) => api.post(CREATE_USER(), newUser);

export const login = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

export const logout = () => auth.signOut();

export const resetPasswordEmailLink = (email, config) =>
  auth.sendPasswordResetEmail(email, config);

export const resetPassword = (code, newPassword) =>
  auth.confirmPasswordReset(code, newPassword);

export const checkAuthState = (callback) => auth.onAuthStateChanged(callback);
