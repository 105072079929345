import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import styled from "styled-components";

import UserRoute from "./UserRoute";
import Preloader from "../components/Preloader";

import { NotFound } from "./NotFound";
import { ErrorPage } from "./ErrorPage";

import {
  ROOT,
  REGISTER,
  CHANGE_PASSWORD_LINK,
  NEW_PASSWORD,
  SUCESS_NEW_PASSWORD,
  ERROR_PAGE,
  USER_HOME,
  USER_MURAL,
  USER_CONTRACTION,
  USER_REGISTRATION_DATA,
  USER_PAYMENTS,
  USER_DIDNT_FIND,
} from "./CONSTANTS";

/**
 * Rotas Comuns
 */
const Login = lazy(() => import("../screens/Auth/Login"));
const Register = lazy(() => import("../screens/Auth/Register"));
const LinkToSubmit = lazy(() =>
  import("../screens/Auth/Recovery/LinkToSubmit")
);
const NewPassword = lazy(() =>
  import("../screens/Auth/Recovery/CreateNewPassword")
);
const SucessMessage = lazy(() =>
  import("../screens/Auth/Recovery/SuccessMessage")
);

/**
 * Rotas do usuário
 */
const UserHome = lazy(() => import("../screens/User/Home"));
const RegistrationData = lazy(() => import("../screens/User/RegistrationData"));
const UserMural = lazy(() => import("../screens/User/Mural"));
const UserContraction = lazy(() => import("../screens/User/Contraction"));
const Payments = lazy(() => import("../screens/User/Payments"));
const DidntFind = lazy(() => import("../screens/User/DidntFind"));

const Switch = styled(AnimatedSwitch)`
  position: relative;

  > div {
    position: absolute;
    width: 100%;
  }
`;

const Router = () => (
  <Suspense fallback={<Preloader />}>
    <Switch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
    >
      <Route exact path={ROOT} component={Login} />
      <Route exact path={REGISTER} component={Register} />
      <Route exact path={CHANGE_PASSWORD_LINK} component={LinkToSubmit} />
      <Route exact path={NEW_PASSWORD} component={NewPassword} />
      <Route exact path={SUCESS_NEW_PASSWORD} component={SucessMessage} />
      <Route exact path={ERROR_PAGE} component={ErrorPage} />

      <UserRoute exact path={USER_HOME} component={UserHome} />
      <UserRoute exact path={USER_MURAL} component={UserMural} />
      <UserRoute exact path={USER_CONTRACTION} component={UserContraction} />
      <UserRoute
        exact
        path={USER_REGISTRATION_DATA}
        component={RegistrationData}
      />
      <UserRoute exact path={USER_PAYMENTS} component={Payments} />
      <UserRoute exact path={USER_DIDNT_FIND} component={DidntFind} />

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Suspense>
);

export default Router;
