import React, { useState, useEffect } from "react";
import { Container, Progress, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";

const LoadingToRedirect = () => {
  const [count, setCount] = useState(5);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    // Redireciona quando a contagem é igual a 0
    if (count === 0) history.push("/");

    return () => clearInterval(interval);
  }, [count, history]);

  return (
    <Container>
      <Alert color="light" className="text-center">
        Redirecionando você em {count} segundos
      </Alert>
      <Progress animated color="success" value={100 - count * 20} />
    </Container>
  );
};

export default LoadingToRedirect;
