export const light = {
  title: "light",
  colors: {
    primary: "#3B3A3A",
    secondary: "#DFDFDF",
    success: "#4C841F",
    info: "#E4DA41",

    shade: "rgba(0, 0, 0, 0.2)",
    tint: "rgba(255, 255, 255, 0.2)",

    white: "#FFFFFF",
    black: "#000000",
    gray: "#C4C4C4;",
    lightGray: "#EFEFEF",
    darkGray: "#878787",

    progress: "#1F844D",
    progressGradient: "linear-gradient(180deg, #50c47e 0%, #4c841f 100%)",
    tooltip: "#8BC450",

    shadow: "0px 0px 73px 16px rgba(0, 0, 0, 0.3)",
    recoveryShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.2);",
    sectionShadow: "0px 0px 4px 2px #EEF0F2;",
    hamburguerShadow: "0px 0px 4px rgba(0, 0, 0, 0.25);",

    // Backgrounds
    bgPrimary: "linear-gradient(180deg, #4C841F 0%, #8BC450 100%)",
    bgSecondary:
      "linear-gradient(265.83deg, #578F28 5.55%, #52A332 53.76%, #4DB73C 94.82%)",
  },
};
